import Events from 'events'
import {PLAYER_TYPE, DEFAULT_MEDIA_BLOCK_CONFIG} from '../config'
import {getLogger} from '../util/log'
import * as CombineMode from './CombineMode'
import createVolumeMeter from './function/createVolumeMeter'
import PacketLoss from '../util/PacketLoss'
import {getPlayerIdFromSessionId, generateSessionId, getStreamType, getUserId} from '../util/util'
import MediaBlockManager from '../util/MediaBlockManager'

const logger = getLogger('BRTC');

export default class BRTCClient extends Events {
    constructor({mode = 'live', codec = 'h264', appId, token, channel, userId, streamType, webrtcInfo, comments, lags}) {

        super();

        this.mode = mode;
        this.codec = codec;
        this.appId = appId;
        this.token = token;
        this.channel = channel;
        this.userId = userId;
        this.streamType = streamType;
        this.webrtcInfo = webrtcInfo;
        this.comments = comments;
        this.lags = Object.assign(DEFAULT_MEDIA_BLOCK_CONFIG, lags);

        this.isJoined = false;
        this.isPublished = false;
        this.locationStream = null;
        this.subscribeQueue = {};
        this.session = '' + generateSessionId(userId, streamType);

        this.canSubscribe = streamType === PLAYER_TYPE.MAIN_CAMERA;

        this.mediaBlockManager = new MediaBlockManager(this.lags);

        this.upState = {
            bytesSentVideo: 0,
            bytesSentAudio: 0,
            uplinkVideoLossRate: 0.00,
            uplinkAudioLossRate: 0.00,
            uplinkVideoBandwidth: 0,
            uplinkAudioBandwidth: 0,
            uplinkFrameRate: 0,
            rtt: 0,
            mediaBlock: this.mediaBlockManager.createMediaBlockInstance(this.session, true, false, this.lags)
        };
        this.downState = {};

        CombineMode.addClient(this.streamType, this);

        this.client = global.BRTC.createClient({
            codec: this.codec,
            mode: this.mode,
            appId: this.appId,
            comments: this.comments
        });

        if (!this.canSubscribe) {
            this.client.setPublishOnly(true);
        }

        this.handleEvents();

        this.streamAddHold = true;
    }

    async join() {
        if (this.isJoined) {
            logger.error('duplicate RtcClient.join() observed');
            throw new Error('join fail');
        }
        try {
            // join the room
            if (this.canSubscribe) {
                await this.client.join(this.channel, +this.session, this.token);
                logger.info('join room success');
                this.isJoined = true;
            }
            this.streamAddHold = false;
            CombineMode.publisherCache(this.streamType);
        }
        catch (error) {
            logger.error('failed to join room because: ' + error);
            throw new Error('join fail');
        }
    }

    async leave() {
        if (!this.isJoined) {
            logger.error('RtcClient.join() not observed');
            throw new Error('leave fail');
        }
        try {
            if (this.isPublished && this.locationStream) {
                await this.unpublish(this.locationStream);
            }
            await this.client.leave();
            logger.info('leave room success');
            this.isJoined = false;
            this.streamAddHold = true;
            this.emit('quit');
        }
        catch (error) {
            this.isJoined = false;
            this.streamAddHold = true;
            logger.error('failed to leave room because: ' + error);
            this.emit('quit');
            throw new Error('leave fail');
        }
    }

    async publish(stream) {
        if (!this.isJoined) {
            if (this.canSubscribe) {
                logger.error('publish() - please join() firstly');
                throw new Error('publish fail');
            }
            else {
                // 非主 session 推流的时候才 join
                await this.client.join(this.channel, +this.session, this.token);
                logger.info('join room success');
                this.isJoined = true;
            }
        }
        if (this.isPublished) {
            logger.info('duplicate RtcClient.publish() observed');
            throw new Error('publish fail');
        }
        if (this.publishTimeout) {
            clearTimeout(this.publishTimeout);
            this.publishTimeout = null;
        }
        try {
            // 发布本地流
            this.publishTimeout = setTimeout(() => {
                this.publishTimeout = null;
                throw new Error('publish fail');
            }, 10 * 1000);

            await this.client.publish(stream);

            clearTimeout(this.publishTimeout);
            this.publishTimeout = null;

            this.isPublished = true;
            this.locationStream = stream;
        }
        catch (error) {
            if (this.publishTimeout) {
                clearTimeout(this.publishTimeout);
                this.publishTimeout = null;
            }
            logger.error('failed to publish local stream ' + error);
            this.isPublished = false;
            throw new Error('publish fail');
        }
    }

    async unpublish(stream) {
        if (!this.isJoined) {
            logger.error('unpublish() - please join() firstly');
            throw new Error('unpublish fail');
        }
        if (!this.isPublished) {
            logger.info('RtcClient.unpublish() called but not published yet');
            return;
        }

        if (this.publishTimeout) {
            clearTimeout(this.publishTimeout);
            this.publishTimeout = null;
        }

        try {
            // 停止发布本地流
            await this.client.unpublish(this.locationStream || stream);
            this.upState.userId = null;
            this.isPublished = false;
            this.locationStream = null;

            // 非主 session 结束推流时 leave room
            if (!this.canSubscribe) {
                await this.client.leave();
                logger.info('leave room success');
                this.isJoined = false;
            }
        }
        catch (error) {
            this.isPublished = false;
            logger.error('failed to unpublish local stream because ' + error);
            if (!/stream has not been published yet/i.test(error)) {
                throw new Error('unpublish fail');
            }
        }
    }

    async _subscribe(stream) {
        if (!this.isJoined) {
            logger.error('subscribe() - please join() firstly');
            throw new Error('subscribe fail');
        }
        try {
            await this.client.subscribe(stream, {video: true, audio: true});
            let sessionId = stream.getUserId();
            let isPush = false;
            let hasLossRate = true;

            this.downState[sessionId] = {
                bytesReceivedVideo: 0,
                bytesReceivedAudio: 0,
                audioPacketLoss: new PacketLoss(),
                videoPacketLoss: new PacketLoss(),
                downlinkVideoBandwidth: 0,
                downlinkAudioBandwidth: 0,
                mediaBlock: this.mediaBlockManager.createMediaBlockInstance(sessionId, isPush, hasLossRate),
                stream
            };
            return {
                userId: getPlayerIdFromSessionId(sessionId),
                stream: stream
            };
        }
        catch (error) {
            logger.error('failed to subscribe remote stream because ' + error);
            throw new Error('subscribe fail');
        }
    }

    async subscribe(stream) {
        try {
            return await CombineMode.subscribe(stream);
        }
        catch(event) {
            throw new Error('subscribe fail');
        }
    }

    async _mutePeer(stream, media) {
        if (!this.isJoined) {
            logger.error('subscribe() - please join() firstly');
            throw new Error('subscribe fail');
        }
        if (!stream) {
            throw new Error('subscribe fail - stream is null');
        }
        if (!stream.stream) {
            throw new Error('subscribe fail - stream is destroy');
        }
        try {
            logger.info('mutePeer', media);
            try {
                await this.client.unsubscribe(stream);
            }
            catch (error) {
                logger.error('_mutePeer: failed to unsubscribe remote stream because ' + error);
            }
            
            stream && await this.client.subscribe(stream, media);

            if (!media.video && stream && this.downState[stream.getUserId()]) {
                this.downState[stream.getUserId()].downlinkVideoBandwidth = 0;
            }
        }
        catch (error) {
            logger.error('failed to subscribe remote stream because ' + error);
            throw new Error('_mutePeer fail');
        }
    }

    async mutePeer(stream, media) {
        try {
            await CombineMode.mutePeer(stream, media);
        }
        catch(event) {
            throw new Error('subscribe fail');
        }
    }

    async _unsubscribe(stream) {
        if (!this.isJoined) {
            logger.error('unsubscribe() - please join() firstly');
            throw new Error('unsubscribe fail');
        }
        try {
            await this.client.unsubscribe(stream);
            let sessionId = stream.getUserId();
            if (this.downState) {
                delete this.downState[sessionId];
            }
        }
        catch (error) {
            logger.info('failed to unsubscribe remote stream because ' + error);
            throw new Error('unsubscribe fail');
        }
    }

    async unsubscribe(stream) {
        try {
            await CombineMode.unsubscribe(stream);
        }
        catch(event) {
            throw new Error('unsubscribe fail');
        }
    }

    handleEvents() {
        // 处理 client 错误事件，错误均为不可恢复错误，建议提示用户后刷新页面
        this.client.on('error', async error => {
            logger.error(error);
            if (error && error.getCode && error.getCode() === 4002) {
                await CombineMode.quit();
            }
            else {
                await this.destroy();
            }
        });

        if (this.canSubscribe) {
            // 处理远端流增加事件
            this.client.on('stream-added', evt => {
                const remoteStream = evt.stream;
                const id = remoteStream.getUserId();
                const sessionId = remoteStream.getUserId();
                // remoteStream.stream = remoteStream.mediaStream_;

                if (this.userId === getUserId(sessionId)) {
                    // this.client.unsubscribe(remoteStream);
                    remoteStream.destroy();
                }
                else {
                    // createVolumeMeter(remoteStream);
                    logger.info(`remote stream added: [${sessionId}] ID: ${id} type: ${remoteStream.getType()}`);
                    CombineMode.addPublisher(sessionId, remoteStream);
                }
            });

            // 处理远端流被删除事件
            this.client.on('stream-removed', evt => {
                const remoteStream = evt.stream;
                const id = remoteStream.getUserId();
                const sessionId = remoteStream.getUserId();
                if (this.userId !== getUserId(sessionId)) {
                    logger.info(`stream-removed ID: ${id}  type: ${remoteStream.getType()}`);
                    CombineMode.removePublish(sessionId, remoteStream);
                    remoteStream.destroy();
                }
                if (this.downState[sessionId]) {
                    delete this.downState[sessionId];
                }
            });

            this.client.on('stream-updated', evt => {
                const remoteStream = evt.stream;
                const id = remoteStream.getUserId();
                const sessionId = remoteStream.getUserId();
                if (this.userId !== getUserId(sessionId)) {
                    logger.info(`stream-update ID: ${id}  type: ${remoteStream.getType()}`);
                    CombineMode.updatePublish(sessionId, remoteStream);
                }
            });
        }

        this.stateTimer = setInterval(() => {
            if (this.isPublished) {
                this.client.publishStream.getStats().then(stats => {
                    this.upState.uplinkAudioBandwidth = stats.audioBytesSent - this.upState.bytesSentAudio;
                    this.upState.bytesSentAudio = stats.audioBytesSent;
                    this.upState.uplinkVideoBandwidth = stats.videoBytesSent - this.upState.bytesSentVideo;
                    this.upState.bytesSentVideo = stats.videoBytesSent;
                    this.upState.frameRate = stats.framesSent - this.upState.framesSent;
                    this.upState.framesSent = stats.framesSent;
                    this.upState.rtt = stats.rtt;
                });
            }

            if (this.canSubscribe && this.downState) {
                if (Object.keys(this.downState).length) {
                    Object.keys(this.downState).forEach((userId, index) => {
                        let downState = this.downState[userId];
                        if (downState && downState.stream) {
                            downState.stream.getStats().then(stats => {
                                downState.downlinkAudioBandwidth = stats.audioBytesReceived - downState.bytesReceivedAudio;
                                downState.bytesReceivedAudio = stats.audioBytesReceived;
                                downState.audioPacketLoss.addData(stats.audioPacketsReceived, stats.audioPacketsLost);
                                downState.downlinkVideoBandwidth = stats.videoBytesReceived - downState.bytesReceivedVideo;
                                downState.bytesReceivedVideo = stats.videoBytesReceived;
                                downState.videoPacketLoss.addData(stats.videoPacketsReceived, stats.videoPacketsLost);
                                downState.frameRate = stats.framesDecoded - (downState.framesDecoded ? downState.framesDecoded : 0);
                                downState.framesDecoded = stats.framesDecoded; 
                            });
                        }
                    });
                }
            }
            if (this.isPublished) {
                this.emit('rtcat-stats', {
                    isPublish: true,
                    log: this.upState
                });
            }

            if (this.canSubscribe && this.downState) {
                for (let sessionId in this.downState) {
                    CombineMode.emitDownloadState(sessionId, {
                        downlinkAudioBandwidth: this.downState[sessionId].downlinkAudioBandwidth,
                        downlinkVideoBandwidth: this.downState[sessionId].downlinkVideoBandwidth,
                        downlinkAudioLossRate: this.downState[sessionId].audioPacketLoss.getPacketLossRate(),
                        downlinkVideoLossRate: this.downState[sessionId].videoPacketLoss.getPacketLossRate()
                    });
                }
            }
        }, 1000);

        this.blockTimer = setInterval(() => {
            if (this.isPublished) {
                let state = this.upState;
                let mediaBlock = state.mediaBlock;
                let _state = {
                    fps: state.frameRate,
                    audioBandwidth: state.uplinkAudioBandwidth,
                    videoBandwidth: state.uplinkVideoBandwidth
                };
                let isBlock = mediaBlock.isBlock(
                    _state
                );
                window.enableBJYDebugLog && logger.debug('brtc-uplink-state:', this.session, ', current:', _state, ', winAvg:', mediaBlock.getValues());
                if (isBlock) {
                    window.enableBJYDebugLog && logger.debug('brtc-uplink-flency-report', this.session, mediaBlock.getValues());
                    CombineMode.flencyReport(this.session);
                }
            }
            if (this.canSubscribe) {
                for (let sessionId in this.downState) {
                    let state = this.downState[sessionId];
                    let mediaBlock = state.mediaBlock;
                    let _state = {
                        audioLossRate: state.audioPacketLoss.getPacketLossRate(),
                        videoLossRate: state.videoPacketLoss.getPacketLossRate(),
                        fps: state.frameRate,
                        audioBandwidth: state.downlinkAudioBandwidth,
                        videoBandwidth: state.downlinkVideoBandwidth
                    };
                    let isBlock = mediaBlock.isBlock(
                        _state
                    );
                    window.enableBJYDebugLog && logger.debug('brtc-downlink-state:', sessionId, ', current:', _state, ', winAvg:', mediaBlock.getValues());
                    if (isBlock) {
                        window.enableBJYDebugLog && logger.debug('brtc-downlink-flency-report', sessionId, mediaBlock.getValues());
                        CombineMode.flencyReport(sessionId);
                    }
                }
            }

        }, 2000);
    }

    async destroy() {
        if (this.client) {
            this.client.destroy(true);
        }
        this.removeAllListeners();
        CombineMode.removeClient(this.streamType);
        this.emit('quit');
        this.client = null;
    }

    removeAllListeners() {
        clearInterval(this.stateTimer);
        this.stateTimer = null;
        clearInterval(this.blockTimer);
        this.blockTimer = null;
        if (this.client) {
            this.client.off('*');
        }
    }
}