
import {LOSS_RATE_TYPE_AUDIO, LOSS_RATE_TYPE_VIDEO} from '../config'

export default class MediaBlockLossRateStategy {
    /**
     * 
     * @param {*} mediaBlockConfig 
     * @param {*} lossRateType LOSS_RATE_TYPE_AUDIO: 音频 LOSS_RATE_TYPE_VIDEO：视频
     */
    constructor (mediaBlockConfig, lossRateType) {
        const BLOCK_LOSS_RATE_THRESHOLD = 5;
        const BLOCK_TIME_WINDOW_THRESHOLD = 5;
        this.blockLossRateThreshold = mediaBlockConfig && mediaBlockConfig.lossRate || BLOCK_LOSS_RATE_THRESHOLD;
        this.win = mediaBlockConfig && mediaBlockConfig.win || BLOCK_TIME_WINDOW_THRESHOLD;
        this.qunue = [];
        this.lossRateType = lossRateType;
    }

    getName () {
        return this.lossRateType === LOSS_RATE_TYPE_VIDEO ? 'videoLossRate' : 'audioLossRate';
    }

    getValue () {
        return this.getLossRate();
    }

    /**
     * 设置最新的丢包率
     * @param {*} state 
     */
    setMediaState (state) {
        while (this.qunue.length >= this.win) {
            this.qunue.shift()
        }
        if (this.lossRateType === LOSS_RATE_TYPE_VIDEO) {
            if (!state || isNaN(state.videoLossRate)) {
                return;
            }
            this.qunue.push(Number(state && state.videoLossRate))
        }
        else {
            if (!state || isNaN(state.audioLossRate)) {
                return;
            }
            this.qunue.push(Number(state && state.audioLossRate))
        }
    }

    /**
     * 获取时间窗口内的平均丢包率
     */
    getLossRate () {
        if (this.qunue.length === 0) {
            return 0;
        }
        else {
            let sum =this.qunue.reduce(function (a, b) {
                return parseInt(a) + parseInt(b);
            });
            return sum / this.qunue.length;
        }
    }

    /**
     * 检测是否满足卡顿条件
     */
    checkBlockStatus () {
        if (this.qunue.length < this.win) {
            return false;
        }
        return this.getLossRate() >= this.blockLossRateThreshold;
    }
}