// http://www.fynas.com/ua/search
var list = [
    [ 'alipay', /alipay/ ],
    [ 'wechat', /micromessenger/ ],
    [ 'baiduApp', /baiduboxapp/ ],
    [ 'baidu', /baidubrowser/ ],
    [ 'baidu', /bdbrowser/ ],
    [ 'uc', /ucbrowser/ ],
    [ 'uc', /ucweb/ ],
    [ 'qq', /qqbrowser/ ],
    [ 'qqApp', /qq/ ],
    [ 'ie', /iemobile[ \/]([\d_.]+)/ ],
    // IE10- 所有版本都有的信息是 MSIE x.0
    [ 'ie', /msie[ \/]([\d_.]+)/ ],
    [ 'ie', /trident[ \/]([\d_.]+)/, 4 ],
    [ 'edge', /edge[ \/]([\d_.]+)/ ],
    [ 'chrome', /chrome[ \/]([\d_.]+)/ ],
    [ 'firefox', /firefox[ \/]([\d_.]+)/ ],
    [ 'opera', /opera(?:.*version)?[ \/]([\d_.]+)/ ],
    [ 'safari', /version[ \/]([\d_.]+) safari/ ],
    [ 'safari', /safari/ ]
];

/**
 * 获取 UA 的结构化信息
 *
 * @inner
 * @param {string} ua
 * @return {Object}
 */
function parseUA(ua) {

    var name;
    var version;

    list.forEach(function (item) {
        var match = item[1].exec(ua);
        if (match) {
            name = item[0];
            version = match[1];
            if (version) {
                version = version.replace(/_/g, '.');
                if (item[2]) {
                    version = (parseInt(version, 10) + item[2]) + '.0';
                }
            }
            return false;
        }
    })

    return {
        name: name || '',
        version: version || ''
    };

}

var result = parseUA(navigator.userAgent.toLowerCase());
if (result.name) {
    result[result.name] = true;
}

export default result;