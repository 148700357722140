export default class MediaBlockFrameRateStategy {
    constructor (mediaBlockConfig) {
        const BLOCK_FRAME_RATE_THRESHOLD = 5;
        const BLOCK_TIME_WINDOW_THRESHOLD = 5;
        this.VIDEO_BAND_WIDTH_MIN = 1500; //小于最小带宽时，认为没开视频
        this.blockFrameRateThreshold = mediaBlockConfig && mediaBlockConfig.fps || BLOCK_FRAME_RATE_THRESHOLD;
        this.win = mediaBlockConfig && mediaBlockConfig.win || BLOCK_TIME_WINDOW_THRESHOLD;
        this.qunue = [];
    }

    getName () {
        return 'fps';
    }

    getValue () {
        return this.getFps();
    }

    /**
     * 设置最新的帧率
     * @param {*} state 
     */
    setMediaState (state) {
        if (!state || isNaN(state.fps)) {
            return;
        }
        while (this.qunue.length >= this.win) {
            this.qunue.shift()
        }
        this.qunue.push(Number(state && state.fps))
    }

    /**
     * 获取时间窗口内的平均帧率
     */
    getFps () {
        if (this.qunue.length === 0) {
            return 0;
        }
        else {
            let sum =this.qunue.reduce(function (a, b) {
                return parseInt(a) + parseInt(b);
            });
            return sum / this.qunue.length;
        }
    }

    /**
     * 检测是否满足卡顿条件
     */
    checkBlockStatus (state) {
        if (this.qunue.length < this.win || (state && state.videoBandwidth && state.videoBandwidth < this.VIDEO_BAND_WIDTH_MIN)) {
            return false;
        }
        return this.getFps() < this.blockFrameRateThreshold;
    }
}