import StreamManager from '../util/StreamManager'
import StreamWrapper from '../util/StreamWrapper'
import {
    STREAM_TYPE,
    VIDEO_QUALITY_LOW,
    VIDEO_QUALITY_MEDIUM,
    VIDEO_QUALITY_HIGH,
    VIDEO_QUALITY_SUPER,
    VIDEO_QUALITY_720P,
    VIDEO_QUALITY_1080P
} from '../config'
import {getLogger} from '../util/log';

const logger = getLogger('agroa stream-manager');
const isChrome = !!navigator.webkitGetUserMedia;
// navigator.getDisplayMedia不支持分享音频
const isSupportScreenAudio = !!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia);

function getQualityProfile(quality) {
    // default 480P(suitable for most browser)
    let profile = '480P';
    switch (quality) {
        case VIDEO_QUALITY_LOW:
            profile = '120P';
            break;
        case VIDEO_QUALITY_MEDIUM:
            profile = '240P';
            break;
        case VIDEO_QUALITY_HIGH:
            profile = '480P';
            break;
        case VIDEO_QUALITY_SUPER:
            profile = '720P_6';
            break;
        case VIDEO_QUALITY_720P:
            profile = '720P_2';
            break;
        case VIDEO_QUALITY_1080P:
            profile = '1080P_2';
            break
    }
    return profile
}

const getDisplayMedia = (window.navigator.mediaDevices && window.navigator.mediaDevices.getDisplayMedia)
    ? window.navigator.mediaDevices.getDisplayMedia.bind(window.navigator.mediaDevices)
    : (window.navigator && window.navigator.getDisplayMedia)
        ? window.navigator.getDisplayMedia.bind(window.navigator)
        : null;

class AgoraStreamManager extends StreamManager {
    createAVStream(opts) {
        return new Promise((resolve, reject) => {
            if (!opts.type || opts.type === 'noDevice') {
                resolve()
            }

            const options = this.getAVStreamOptions(opts);
            const stream = global.AgoraRTC.createStream(options);

            options.video && stream.setVideoEncoderConfiguration({
                resolution: opts.size,
                frameRate: {
                    min: opts.fps || 15,
                    max: Math.max(opts.fps || 15, 30)
                },
                bitrate: {
                    min: opts.bandwidth || 100,
                    max: opts.bandwidth || 2000
                }
            });

            stream.init(
                () => resolve(stream),
                err => {
                    let error = new Error();

                    // 被占用
                    if (err.msg === 'MEDIA_OPTION_INVALID') {
                        error.name = 'NotReadableError';
                    }
                    // 没有设备
                    else if (err.msg === 'DEVICES_NOT_FOUND') {
                        error.name = 'NotFoundError';
                    }
                    else {
                        error.name = err.msg;
                    }

                    error.message = err.info;

                    reject(error);
                }
            )
        })
    }

    createScreenStream(opts) {
        return new Promise((resolve, reject) => {
            function createAgoraScreenStream(videoSource, audioSource) {
                // 对 videoSource 和 audioSource 进行处理
                let localStream = global.AgoraRTC.createStream({
                    video: true,
                    audio: opts && opts.audio,
                    audioSource: audioSource,
                    videoSource: videoSource
                });
                logger.info('createAgoraScreenStream: ', opts, opts && opts.agoraDefinition || '720p');
                localStream.setVideoProfile(opts && opts.agoraDefinition || '720p');
                localStream.init(
                    () => {
                        videoSource.onended = () => {
                            logger.info('video end');
                            localStream.dispatchEvent({type: 'video_end'})
                        };
                        resolve(localStream)
                    },
                    err => reject(err)
                )
            }

            if (getDisplayMedia) {
                getDisplayMedia({
                    video: true,
                    audio: isSupportScreenAudio
                }).then((mediaStream) => {
                    createAgoraScreenStream(
                        mediaStream.getVideoTracks()[0],
                        mediaStream.getAudioTracks()[0]
                    );
                }).catch(err => reject(err))
            } else if (document.body.dataset.rtcExtensionId != null) {
                window.addEventListener('message', function once(event) {
                    const {data: {type, streamId}, origin} = event;
                    // NOTE: you should discard foreign events
                    if (origin !== window.location.origin) {
                        console.warn(
                            'ScreenStream: you should discard foreign event from origin:',
                            origin
                        )
                        // return;
                    }
                    // user chose a stream
                    if (type === 'STREAM_SUCCESS') {
                        window.removeEventListener('message', once);
                        navigator.mediaDevices.getUserMedia({
                            audio: false,
                            video: {
                                mandatory: {
                                    chromeMediaSource: 'desktop',
                                    chromeMediaSourceId: streamId,
                                    maxWidth: window.screen.width,
                                    maxHeight: window.screen.height
                                }
                            }
                        }).then((mediaStream) => {
                            let videoSource = mediaStream.getVideoTracks()[0];
                            createAgoraScreenStream(videoSource);
                        })
                    }
                    if (type === 'STREAM_ERROR') {
                        window.removeEventListener('message', once);
                        reject(new Error('permission denied'))
                    }
                });
                window.postMessage({type: 'STREAM_REQUEST'}, '*')
            } else {
                navigator.mediaDevices.getUserMedia({
                    video: {
                        mandatory: {
                            chromeMediaSource: 'screen',
                            maxWidth: window.screen.width,
                            maxHeight: window.screen.height
                        }
                    }
                }).then((mediaStream) => {
                    let videoSource = mediaStream.getVideoTracks()[0];
                    createAgoraScreenStream(videoSource);
                }).catch(e => {
                    reject(new Error('not supported'))
                })
            }
        })
    }

    createHTMLMediaStream({videoElement, bandwidth, fps, quality}) {
        return new Promise((resolve, reject) => {
            if (!videoElement) {
                reject(new Error('videoElement required'))
            }
            const options = this.getHTMLMediaStreamOptions({videoElement, fps});
            logger.info('createHTMLMediaStream: ', options);
            const stream = global.AgoraRTC.createStream(options);
            // todo: size
            stream.setVideoProfile(getQualityProfile(quality));
            stream.setVideoEncoderConfiguration({
                bitrate: {
                    min: bandwidth || 100,
                    max: bandwidth || 2000
                }
            });
            stream.init(
                () => resolve(stream),
                err => reject(err)
            )
        })
    }

    getAVStreamOptions(opts) {
        const fps = opts.fps || this.fps;
        const options = {
            streamID: opts.id,
            video: false,
            audio: false,
            screen: false,
            fps: fps,
            quality: opts.quality,
            attributes: {
                minFrameRate: fps,
                maxFrameRate: fps
            }
        };
        if (opts.cameraId) {
            options.cameraId = opts.cameraId
        }
        if (opts.microphoneId) {
            options.microphoneId = opts.microphoneId
        }
        switch (opts.type) {
            case STREAM_TYPE.AV:
                options.video = true;
                options.audio = true;
                break;
            case STREAM_TYPE.VIDEO:
                options.video = true;
                break;
            case STREAM_TYPE.AUDIO:
                options.audio = true;
                break
        }
        return options
    }

    getHTMLMediaStreamOptions(opts) {
        let localStream = null;
        // fixme(cc): firefox no audio
        const videoElement = opts.videoElement;
        videoElement.captureStream = videoElement.captureStream || videoElement.mozCaptureStream;
        if (videoElement.captureStream) {
            const fps = opts.fps || this.fps;
            localStream = videoElement.captureStream(fps)
        }
        else {
            localStream = videoElement;
            if (isChrome) {
                console.warn('chrome should enable this flag(chrome://flags/#enable-experimental-web-platform-features) for this feature')
            }
            else {
                console.warn('the browser not support capturestream feature now')
            }
        }
        const streamWrapper = new StreamWrapper(localStream);
        const videoTrack = streamWrapper.videoTrack;
        const audioTrack = streamWrapper.audioTrack;
        return {
            video: !!videoTrack,
            audio: !!audioTrack,
            videoSource: videoTrack,
            audioSource: audioTrack
        }
    }
}

export default AgoraStreamManager

