/**
 * 用于订阅流的session
 * @auth yanlingling
 */
import Events from 'events';
import {PLAYER_TYPE} from '../config';
import {getPlayerIdFromSessionId, getStreamType} from '../util/util'
import {getLogger} from '../util/log';
import MediaBlockManager from '../util/MediaBlockManager';

const logger = getLogger('agroa main-session');

/**
 * 每种类型的流,已经有的publisher。
 * @type {{}}
 */
let publishedQueue = {
    [PLAYER_TYPE.MAIN_CAMERA]: [],
    [PLAYER_TYPE.ASSIST_CAMERA]: [],
    [PLAYER_TYPE.MEDIA_FILE]: [],
    [PLAYER_TYPE.MAIN_SCREEN_SHARE]: [],
    [PLAYER_TYPE.ASSIST_SCREEN_SHARE]: []
};

class MainSession extends Events {

    /**
     * 后进入的用户触发一下之前已经有的publisher
     * @param {number} streamType 流类型
     */
    sendPublishedQueue(streamType) {
        let me = this;
        let queue = publishedQueue[streamType];
        if (streamType === PLAYER_TYPE.MAIN_CAMERA) {
            return;
        }

        queue && queue.forEach(function (evt, key) {
            const stream = evt.stream;
            const sessionId = stream.getId();
            const streamType = getStreamType(sessionId);
            const playerId = getPlayerIdFromSessionId(sessionId);
            logger.info('main addPublisher for sendPublishedQueue: ', playerId, stream, streamType);
            me.emit('addPublisher', null, {playerId, stream, streamType})
        });
    }

    /**
     * 创建连接
     */
    createSession({mode = 'live', codec = 'h264', appId, token, channel, playerId, userId, webrtcInfo, lags} = {}) {
        this.playerId = playerId;
        this.sessionId = parseInt(userId);

        this._streamLog = {};
        console.group('create main session');

        console.table({
            mode,
            appId,
            token,
            codec,
            channel,
            playerId,
            userId,
            webrtcInfo,
            lags
        });

        this.lags = lags
        this.mediaBlockManager = new MediaBlockManager(lags);

        const sessionTimeout = new Promise( (resolve, reject) => {
            let wait = setTimeout(() => {
                clearTimeout(wait);
                reject(-1) // Create Session Timeout
            }, 15 * 1000)
        });
        const sessionConnection = new Promise(resolve => {
            if (this.session != null) {
                if (this.connected === false) {
                    logger.info('try to join main channel: ', webrtcInfo.token, this.sessionId);
                    this.session.join(
                        webrtcInfo.token, channel, this.sessionId,
                        () => {
                            logger.info('join main channel succeed');
                            this.connected = true;
                            resolve(this.session)
                        },
                        (err) => {
                            this.emit('sessionError', null, {playerId});
                            logger.error('join main channel fail: ', err)
                        }
                    )
                }
            } else {
                this.session = global.AgoraRTC.createClient({
                    mode: mode,
                    codec: codec
                });
                this.session.init(
                    appId,
                    () => {
                        let session = this.session;
                        session.on('error', (err) => {
                            logger.error('session error: ', err.reason);
                            if (err.reason === 'DYNAMIC_KEY_TIMEOUT') {
                                this.emit('renewWebrtcInfo')
                            }
                        });

                        session.on('stream-added', (evt) => {
                            const stream = evt.stream;
                            const sessionId = stream.getId();
                            const streamType = getStreamType(sessionId);
                            const playerId = getPlayerIdFromSessionId(sessionId);
                            publishedQueue[streamType].push(evt);
                            logger.info('main stream-added');
                            logger.info('main addPublisher by stream-added: ', playerId, stream, streamType);

                            this.emit('addPublisher', null, {
                                playerId,
                                stream,
                                streamType
                            })
                        });

                        session.on('stream-published', (evt) => {
                            const stream = evt.stream;
                            // 只发出自己对应流的消息
                            logger.info(`new stream published in main session: ${stream.getId()}`);
                            this.emit('published', null, {
                                stream,
                                sessionId: this.sessionId,
                                playerId: playerId
                            })
                        });

                        session.on('stream-subscribed', (evt) => {
                            const stream = evt.stream;
                            const sessionId = stream.getId();
                            const streamType = getStreamType(sessionId);
                            const playerId = getPlayerIdFromSessionId(sessionId);
                            logger.info(`${sessionId} subscribe stream ${sessionId} successfully`);
                            // add remote stream
                            this.emit('addStream', null, {
                                playerId,
                                stream,
                                streamType
                            });

                            //避免某些情况下timer未销毁
                            if(this._streamLog[sessionId]) {
                                clearInterval(this._streamLog[sessionId].timer);
                                clearInterval(this._streamLog[sessionId].blockTimer);
                            }
                            // sessionId标记playid和streamType
                            this._streamLog[sessionId] = {
                                log: {
                                    videoReceiveBytes: 0,
                                    audioReceiveBytes: 0
                                },
                                mediaBlock: this.mediaBlockManager.createMediaBlockInstance(playerId, false, true, this.lags),
                                timer: setInterval(() => {
                                    stream.getStats((log) => {
                                        let prevVideoReceiveBytes = this._streamLog[sessionId].log.videoReceiveBytes;
                                        let prevAudioReceiveBytes = this._streamLog[sessionId].log.audioReceiveBytes;
                                        let downlinkVideoLossRate = (log.videoReceivePacketsLost / log.videoReceivePackets).toFixed(2);
                                        let downlinkAudioLossRate = (log.audioReceivePacketsLost / log.audioReceivePackets).toFixed(2);
                                        let downlinkVideoBandwidth = 8 * (log.videoReceiveBytes - prevVideoReceiveBytes) / 1000;
                                        let downlinkAudioBandwidth = 8 * (log.audioReceiveBytes - prevAudioReceiveBytes) / 1000;
                                        let rtt = 0;
                                        this.emit('downlinkStats', null, {
                                            sessionId,
                                            downlinkVideoLossRate: downlinkVideoLossRate,
                                            downlinkAudioLossRate: downlinkAudioLossRate,
                                            downlinkVideoBandwidth: downlinkVideoBandwidth,
                                            downlinkAudioBandwidth: downlinkAudioBandwidth,
                                            rtt: rtt
                                        });
                                        this._streamLog[sessionId].log = log;
                                    })
                                }, 1000),
                                blockTimer: setInterval(() => {
                                    let state = this._streamLog[sessionId];
                                    let log = state && state.log || {};
                                    let mediaBlock = state && state.mediaBlock;
                                    let _state = {
                                        audioLossRate: (log.audioReceivePacketsLost / log.audioReceivePackets).toFixed(2),
                                        videoLossRate: (log.videoReceivePacketsLost / log.videoReceivePackets).toFixed(2),
                                        fps: log.videoReceiveFrameRate
                                    };
                                    if (mediaBlock) {
                                        let isBlock = mediaBlock.isBlock(
                                            _state
                                        );
                                        window.enableBJYDebugLog && logger.debug('agora-downlink-state:', getPlayerIdFromSessionId(sessionId), ', current:', _state, ', winAvg:', mediaBlock.getValues());
                                        if (isBlock) {
                                            window.enableBJYDebugLog && logger.debug('agora-downlink-flency-report', getPlayerIdFromSessionId(sessionId), mediaBlock.getValues());
                                            this.emit('flency-report', null, { sessionId: sessionId });
                                        }
                                    }
                                }, 2000)
                            }
                        });

                        session.on('stream-removed', (evt) => {
                            const stream = evt.stream;
                            const sessionId = stream.getId();
                            const playerId = getPlayerIdFromSessionId(sessionId);
                            const streamType = getStreamType(sessionId);
                            logger.info(`remote stream is removed: ${sessionId}`);

                            if (this._streamLog[sessionId]) {
                                clearInterval(this._streamLog[sessionId].timer);
                                clearInterval(this._streamLog[sessionId].blockTimer);
                                delete this._streamLog[sessionId]
                            }
                            let queue = publishedQueue[streamType];
                            // 发布队列删除
                            queue.splice(queue.findIndex(item => item.stream.getId() === sessionId), 1);
                            this.emit('cleanStream', null, {
                                playerId,
                                streamType
                            });
                            this.emit('removePublisher', null, {
                                playerId,
                                streamType
                            });
                        });

                        // 有推流端离开时
                        session.on('peer-leave', (evt) => {
                            const sessionId = evt.uid;
                            const streamType = getStreamType(sessionId);
                            const playerId = getPlayerIdFromSessionId(sessionId);
                            const stream = evt.stream;
                            // 不一定有流，加判断
                            if (stream) {
                                logger.info(`remote stream is removed: ${stream.getId()}`);
                                if (this._streamLog[sessionId]) {
                                    clearInterval(this._streamLog[sessionId].timer);
                                    clearInterval(this._streamLog[sessionId].blockTimer);
                                    delete this._streamLog[sessionId]
                                }
                                this.emit('cleanStream', null, {
                                    playerId,
                                    streamType
                                })
                            }
                            logger.info(`${sessionId} leaved from this channel`);
                            this.emit('removePublisher', null, {
                                playerId,
                                streamType
                            })
                        });
                        session.join(
                            webrtcInfo.token, channel, this.sessionId,
                            () => {
                                logger.info('join main channel succeed');
                                this.connected = true;
                                resolve(session)
                            },
                            (err) => {
                                this.emit('sessionError', null, {playerId});
                                logger.error('join main channel fail', err)
                            }
                        )
                    },
                    (err) => {
                        logger.error('AgoraRTC client init fail: ', err)
                    }
                )
            }
        });
        console.groupEnd('create main session');
        return Promise.race([sessionTimeout, sessionConnection])
    }

    /**
     * 销毁连接
     */
    _destroySession() {
        const sessionDisconnect = new Promise((resolve, reject) => {
            Object.keys(this._streamLog).forEach((key) => {
                clearInterval(this._streamLog[key].timer)
                clearInterval(this._streamLog[key].blockTimer)
            });
            this._streamLog = {};
            this.connected = false;
            logger.info('leave main session');
            this.session.leave(
                () => {
                    logger.info('leave main channel successfully: ', this._sessionId);
                    resolve()
                },
                (err) => {
                    logger.error('leave main channel failed: ', err, this._sessionId);
                    reject(err)
                }
            )
        });
        return sessionDisconnect;
    }
}
export default MainSession
